.app-mobile-menu {
  .menu-button {
    padding: 0.25rem 0;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 8px;
    outline: none;

    &:focus {
      outline: none;
    }

    .menu-button-icon {
      display: inline-block;
      vertical-align: middle;
      content: "";
      width: 39px;
      height: 26px;
      position: relative;
      cursor: pointer;
      background: 0 0 !important;

      & > span {
        content: "";
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #22a6b3;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0);
        transition: 0.25s ease-in-out;
        top: 11px;
        transform-origin: left center;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #22a6b3;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0);
        transition: 0.25s ease-in-out;
        top: 0;
        transform-origin: left center;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #22a6b3;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0);
        transition: 0.25s ease-in-out;
        top: 22px;
        transform-origin: left center;
      }

      &.toggle-icon > span {
        width: 0%;
        opacity: 0;
      }

      &.toggle-icon::before {
        transform: rotate(45deg);
        top: 0;
        left: 8px;
        width: 33px;
      }

      &.toggle-icon::after {
        transform: rotate(-45deg);
        top: 23px;
        left: 8px;
        width: 33px;
      }
    }
  }
}
