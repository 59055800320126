.stripe-payment-request-button {
  min-width: 180px;

  .placeholder-payment-button {
    width: 100%;
    height: 42px;
    text-transform: unset;
    background-color: #3C3563;
  }
}
