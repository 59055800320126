@import "../../../theme/scss/variables.scss";

.sorting-select-box {
  height: 42px;

  .sorting-select {
    height: 42px;
    border-color: $secondary;
  }
}
