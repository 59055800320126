.add-vehicle-info-item {
  min-height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);

  .info-item-label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  .info-item-value {
    min-height: 32px;
    line-height: 26px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
}
