.auth-social-group {
  .info-text {
    font-size: 15px;
  }

  .header-text {
    font-size: 24px;
  }

  .title-text {
    font-size: 14px;
  }

  .checkbox-field {
    & > span:first-child {
      align-self: start;
      margin-top: -6px;
    }
  }

  @media (min-width: 769px) {
    .auth-title {
      margin-left: -22px;
      margin-right: -22px;
    }
  }
}
