.action-button {
  height: 42px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;

  &.fixed-size {
    min-width: 180px;
  }

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

  &.disabled {
    opacity: 0.65;
    color: #ffffff;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12),
      0 8px 10px -5px rgba(0, 0, 0, 0.2);
  }

  .circular-process {
    margin-left: 12px;
  }

  &.cancel-button {
    background-color: #999999 !important;
  }
}
