.product-detail-item {
  font-size: 14px;
  padding: 2px 0;
  margin-left: 20px;

  & > svg {
    color: #61ce70;
  }

  &.special > svg {
    color: #23a455;
  }
}
