.stripe-checkout-form {
  .card-element {
    height: 48px;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;

    & > div {
      width: 100%;
    }
  }

  .circular-progress {
    color: #ffffff;
    margin-left: 12px;
  }

  .stripe-disclaimer {
    .stripe-description {
      font-size: 11px;
    }
  }

  .actions-container {
    .dismiss-button {
      background-color: #999999;
    }
  }

  .email-input {
    height: 48px;
    border-radius: 4px;
    border: 1px solid #ced4da;
  }
}
