.social-group-container {
  width: 90%;
  padding: 36px 24px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);

  @media (min-width: 769px) {
    width: auto;
    padding: 64px;
    min-width: 510px;
    max-width: 510px;

    &.trial-container {
      padding: 36px 64px;
    }
  }

  .linear-progress {
    top: 0;
    left: 0;
    right: 0;
  }
}
