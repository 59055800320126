.vehicle-list-cell {
  height: 34px;
  margin-left: 1px;

  .cell-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
