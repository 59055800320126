.car-general-info-card {
  .car-wrm-container {
    width: 163px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #979797;
  }

  .trade-logo {
    left: 12px;
  }
}
