.scroll-box {
  max-width: 100%;
  position: relative;
  overscroll-behavior: contain;

  .headers {
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.26) 5px 10px 14px -6px;
  }

  .tracks,
  .scroller {
    display: flex;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .scroller {
    overflow-x: hidden;
  }

  .tracks {
    overflow: auto;
    scroll-snap-type: x mandatory;
  }

  .scenes::-webkit-scrollbar,
  .scroller::-webkit-scrollbar {
    display: none;
  }

  .track {
    flex: 1 0 32%;
    max-width: 320px;
    scroll-snap-align: start;

    &.small-track {
      max-width: 107.25px;

      &:first-child {
        max-width: 254px;
      }
    }
  }

  .track + .track {
    margin-left: -1px;
  }

  .heading {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border: solid #fff;
    border-width: 0 1px;
    z-index: 1;
    background: #efefef;
    font-weight: 700;
  }

  .entry {
    border: 1px solid #ebebeb;
    border-top: 0;
    background: #fff;
    height: 8em;
    padding: 1em;
  }

  @media (max-width: 767px) {
    .track {
      flex: 1 0 50% !important;
      max-width: 50% !important;

      &.first-track {
          flex: 1 0 90% !important;
          max-width: 90% !important;
      }
    }
  }
}
