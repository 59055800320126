.table-buy-report {
    height: 100%;
    width: 100%;
    position: absolute;
    background: #fff;

    .report-content {
      border-radius: 8px;
      border: 1px solid #eb4d4b;
      background: #efefef;
      position: relative;
      height: 100%;
      margin: 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;

      .report-icon {
        margin: 0 auto 5px;
      }

      .report-action {
        text-decoration: underline;
        font-weight: 500;
        cursor: pointer;
      }
    }
}
