@font-face {
  font-family: "Museo Sans";
  src: url("../fonts/MuseoSans/MuseoSans_100.otf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Museo Sans";
  src: url("../fonts/MuseoSans/MuseoSans_500.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Museo Sans";
  src: url("../fonts/MuseoSans/MuseoSans_700.otf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Museo Sans";
  src: url("../fonts/MuseoSans/MuseoSans_900.otf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
