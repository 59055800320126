.compare-sticky-header {
  top: 0;
  z-index: 2;

  .compare-container {
    @media (min-width: 768px) {
      padding-right: 40px;
    }

    .compare-button {
      width: 175.59px;
    }
  }
}
