.app-header {
  height: 85px;
  z-index: 100;

  .header-wrapper {
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15);
    transition: all 150ms ease 0s;

    .header-item {
      border: none;
      outline: none;
      color: #30336b;
      background: none;
      font-weight: 500;
      transition: color 0.2s;

      &:hover {
        color: #eb4d4b;
      }
    }
  }
}
