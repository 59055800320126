@import "variables";

@import "~bootstrap/scss/variables";

// Fonts
.font-weight-medium {
  font-weight: $font-weight-medium !important;
}

// Text
.text-black {
  color: $black;
}

.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}

// Controls
.cursor-pointer {
  cursor: pointer;
}

// Block
.d-grid {
  display: -ms-grid !important;
  display: grid !important;
}

// Other
.outline-none {
  outline: none;

  &:focus {
  outline: none;
  }
}

.position-absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (min-width: 576px) {
  .d-sm-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
}

@media (min-width: 768px) {
  .d-md-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
}

@media (min-width: 992px) {
  .d-lg-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
}
