.vehicle-list-table {
  .vehicle-list-table-title {
    height: 34px;
  }

  .list-table-report {
    height: 136px;
    position: unset;
    background-color: transparent;
  }
}
