.screen-header {
  height: 70px;

  .header-back-button {
    left: 15px;
  }

  .screen-header-title {
    @media (max-width: 767px) {
      font-size: 20px !important;
      line-height: 20px !important;
    }
  }
}
