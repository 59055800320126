.icons {
  overflow: visible;

  &.gas-icon {
    width: 35px;
    height: 35px;
  }

  &.rotate-mobile-icon {
    width: 35px;
    height: 35px;

    fill: #ffffff;
    stroke: #ffffff;
  }

  &.paint-icon {
    width: 31px;
    height: 27px;
  }

  &.body-icon {
    width: 39px;
    height: 21px;
  }

  &.pod-lock-icon {
    width: 19px;
    height: 24px;
  }

  &.car-round-icon {
    width: 54px;
    height: 54px;
  }

  &.stripe-powered {
    width: 165px;
    height: 36px;
  }
}
