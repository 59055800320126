.questionnaire_conteiner {
  display: flex;
  justify-content: center;
  color: #333333;
  margin-bottom: 25px;
  font-family: "Inter" !important;
}

.questionnaire_block {
  margin-top: 45px;
  max-width: 510px;
  border: 1px solid rgba(0, 0, 0, 0.22);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  border-radius: 6px;
}

.questionnaire_block h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  height: 85px;
}

.err_found {
  text-align: center;
  height: 150px;
  font-size: 24px;
  color: #f91919;
  margin: 50px;
  padding-top: 20px;
}

:is(.block1, .block2, .questions_block) {
  margin: 0 45px 70px 45px;
  position: relative;
}

:is(.block1, .block2) select {
  cursor: pointer;
  padding: 0 20px;
  height: 60px;
  background: #ffffff;
  width: 100%;
  outline: none;
}

.block1 p {
  text-align: start;
  margin-bottom: 15px;
}

.block2 p {
  margin-bottom: 20px;
  text-align: start;
  font-size: 24px;
}

.block3 + div {
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  margin-bottom: 35px;
  border-radius: 6px;
  color: #ffffff;
  box-shadow: none !important;
  font-size: 24px;
  transition: opacity 0.15s ease-in-out;
}

.block3 p {
  text-align: start;
  margin-left: 45px;
}

.button_title {
  margin: 0 auto;
}

.questions_block > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.questions_block > form > label {
  text-align: center;
}

.text_field > div {
  width: 100% !important;
}

.icon {
  position: absolute;
  left: 15px;
}

.icon > svg {
  width: 50px;
  height: 50px;
}

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  height: 60px;
  margin-bottom: 35px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.22);
  color: #ffffff;
  font-size: 24px;
  transition: opacity 0.15s ease-in-out;
}

.button:hover {
  opacity: 0.85;
}

.lookup_info {
  display: flex;
  flex-direction: column;
  margin: 50px 70px;
}

.register div {
  box-shadow: none;
}

.register h2 {
  display: none;
}
