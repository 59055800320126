.car-buy-credits-card {
  min-width: 0;
  border-radius: 8px;
  height: fit-content;
  background-color: #efefef;

  .card-icon {
    margin-top: -22px;
  }

  .card-title {
    color: #22a6b3;
  }

  .card-description {
    font-size: 16px;
    line-height: 24px;
  }

  .card-list-item {
    font-size: 16px;
  }

  .card-price {
    font-size: 24px;
  }

  .card-buy-button {
    height: 52px;
    font-weight: 500;
    background-color: #eb4d4b;
  }

  .stripe-note {
    font-size: 12px;
  }

  .stripe-link {
    color: #000000;
    font-size: 12px;
    text-decoration: underline;
  }
}
