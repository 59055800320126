// Colors
$white: #ffffff;

$gray: #6c757d;

$lightAqua: #c7ecee;

$info: #c7ecee;
$muted: #efefef;
$danger: #eb4d4b;
$orange: #ff9800;
$yellow: #fef200;
$warning: #f9ca24;
$primary: #30336b;
$success: #28a745;
$secondary: #22a6b3;

$blue: #0497d8;

$text-muted: $muted;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "blue": $blue,
    "gray": $gray,
    "info": $info,
    "muted": $muted,
    "danger": $danger,
    "orange": $orange,
    "yellow": $yellow,
    "warning": $warning,
    "primary": $primary,
    "success": $success,
    "secondary": $secondary,
    "light-aqua": $lightAqua,
  ),
  $theme-colors
);

// Tables
$table-cell-padding: 0 8px;
$table-accent-bg: $text-muted;

// Fonts
$body-color: $primary;
$font-weight-medium: 500;
$font-family-base: "Museo Sans", "Arial", sans-serif;

// Spacers
$spacer: 1rem !default;
$spacers: () !default;

$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 2,
    ),
    6: (
      $spacer * 3,
    ),
    7: (
      $spacer * 4,
    ),
  ),
  $spacers
);
