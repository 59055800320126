.register-form {
  .stripe-checkout-form {
    max-width: 390px;
  }

  .gdpr-info {
    font-size: 11px;
  }

  .error-text {
    font-size: 14px;
  }

  .stripe-secure-text {
    font-size: 14px;
  }

  .marketing-checkbox {
    & > span:first-child {
      width: 42px;
      height: 42px;
      margin-top: -9px;
      align-self: flex-start;
    }
  }

  .description-text {
    font-size: 14px;
  }

  .linear-progress {
    top: 0;
    left: 0;
    right: 0;
  }
}
