.short-list-card-details-item {
  .detail-value {
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
  .detail-title {
    font-size: 12px;
    line-height: 18px;

    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
}
