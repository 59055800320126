.header-vehicle-info {
  max-width: 280px;

  .header-image {
    width: 60%;
  }

  .header-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .buy-report-button {
    min-width: auto;
  }

  .remove-button {
    right: 0;
    background: none;
  }
}
