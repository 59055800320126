.auth-register-container {
  .details-row {
    height: 78px;
    padding-bottom: 0;
  }
  .salvage-warning {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .salvage-text {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
