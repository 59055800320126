.card-details-table {
  .card-title {
    color: #22a6b3;
    font-size: 14px;
  }

  .table-header {
    width: 1px;
    font-size: 12px;
    line-height: 28px;
  }

  .table-data {
    font-size: 12px;
    line-height: 28px;
  }
}
