.app-route-layout {
  .loader-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .loader-process {
      top: 12px;
    }
  }
}
