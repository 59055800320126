.motorway-widget {
  @media (min-width: 1024px) {
    width: 80%;
  }

  .widget-wrapper-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .widget-wrapper {
      &.with-lines {
        &:before {
          width: 80px;
          content: "";
          height: 100%;
          display: block;
          position: absolute;
          min-height: 2000px;
          background-color: #fff;
          left: calc(50% + 170px);
          transform: rotate(-30deg);
          bottom: calc(100% - 210px);
          transform-origin: bottom right;
          -webkit-transform: rotate(-30deg);
          -webkit-transform-origin: bottom right;

          @media (min-width: 1024px) and (max-width: 1199px) {
            left: calc(50% + 120px);
          }

          @media (min-width: 768px) and (max-width: 1023px) {
            left: calc(50% + 80px);
            bottom: calc(100% - 170px);
          }
        }

        &:after {
          top: 180px;
          width: 80px;
          content: "";
          height: 100%;
          display: block;
          position: absolute;
          min-height: 2000px;
          background-color: #fff;
          left: calc(50% + 170px);
          transform: rotate(30deg);
          transform-origin: top right;

          @media (min-width: 1024px) and (max-width: 1199px) {
            top: 230px;
            left: calc(50% + 100px);
          }

          @media (min-width: 768px) and (max-width: 1023px) {
            top: 230px;
            left: calc(50% + 80px);
          }
        }
      }
    }
  }

  .widget-container {
    z-index: 1;

    & > h1 {
      font-family: NewTransportSemi, Helvetica, Arial, sans-serif;
    }

    & > h2 {
      font-family: NewTransport, Helvetica, Arial, sans-serif;
    }

    .input-container {
      @media (min-width: 768px) {
        width: 70%;
        align-self: center;
      }

      .vrm-input {
        -webkit-appearance: none;
        background-color: #fff;
        border-radius: 4px;
        border: solid 2px #e2e1d9;
        float: left;
        font-family: vrm,Helvetica,sans-serif;
        font-size: 32px;
        height: 64px;
        letter-spacing: 1px;
        line-height: 40px;
        outline: 0;
        padding: 12px 16px;
        text-align: center;
        text-transform: uppercase;
        transition: box-shadow .2s linear;
        width: 100%;

        &:-webkit-autofill,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:hover {
          background-color: #ffeb14;
        }
        &::-webkit-input-placeholder {
          font-family: vrm, Helvetica, sans-serif;
        }
        &::-moz-placeholder {
          font-family: vrm, Helvetica, sans-serif;
        }
        &:-ms-input-placeholder {
          font-family: vrm, Helvetica, sans-serif;
        }
        &:-moz-placeholder {
          font-family: vrm, Helvetica, sans-serif;
        }
        &::-webkit-input-placeholder {
          color: rgba(175, 173, 163, 1);
          font-family: vrm, Helvetica, sans-serif;
        }
        &:focus {
          box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
        }
      }

      .widget-button {
        height: 64px;
        font-size: 18px;
        text-transform: unset;
        font-family: NewTransportSemi, Helvetica, Arial, sans-serif;

        @media (min-width: 1199px) {
          font-size: 24px;
          width: 460px;
        }

        & > .MuiButton-label:after {
          width: 24px;
          content: "";
          height: 24px;
          display: inline-block;
          background-position: 50%;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik0xOCAxMmwtNiA5SDZsNi05LTYtOWg2LjAxeiIvPjwvZGVmcz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxtYXNrIGlkPSJiIiBmaWxsPSIjZmZmIj48dXNlIHhsaW5rOmhyZWY9IiNhIi8+PC9tYXNrPjx1c2UgZmlsbD0iIzAwMCIgdHJhbnNmb3JtPSJtYXRyaXgoMSAwIDAgLTEgMCAyNCkiIHhsaW5rOmhyZWY9IiNhIi8+PGcgZmlsbD0iI2ZmZiIgbWFzaz0idXJsKCNiKSI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIvPjwvZz48L2c+PC9zdmc+);
        }
      }
    }

    .logo-container {
      @media (min-width: 768px) {
        width: 70%;
        align-self: center;
      }
    }
  }
}
