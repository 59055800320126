.auth-social-button {
  width: 80%;
  border: none;
  height: 56px;
  user-select: none;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 4px 0;

  @media (max-width: 768px) {
    width: auto;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:hover {
    opacity: 0.8;
  }

  .icon-container {
    top: 2px;
    left: 2px;
    width: 52px;
    height: 52px;
    padding: 12px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .title {
    font-size: 14px;
  }
}
