.main-dialog {
  & > div {
    width: 100%;
  }

  .main-dialog-title {
    min-height: 57px;

    & > * {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .main-dialog-content {
    .linear-progress {
      top: 0;
      left: 0;
      right: 0;
    }
  }
}
