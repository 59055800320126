.auth-product-item {
  background-color: #f7f7f7;

  .product-item-title {
    font-weight: 600;
    line-height: 28px;
  }

  .product-item-sub-title {
    font-size: 13px;
    margin: 5px;
  }

  .product-price-sub {
    font-size: 19px;
    font-weight: 800;
  }

  .product-price-sup {
    font-size: 65px;
    line-height: 0.8;
    font-weight: 800;
  }

  .product-item-button {
    border-radius: 4px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
  }

  .product-item-description {
    font-size: 13px;
  }
}
