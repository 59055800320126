@import "../../../theme/scss/variables";

.add-vehicle-form {
  ::placeholder {
    color: #a2a2a2;
    text-overflow: ellipsis;
    text-transform: none !important;
  }

  .form-vrm-input-container {
    height: 41px;
  }
}
