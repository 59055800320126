.select-ui {
  .reason-label {
    white-space: nowrap;

    &.none-reason-label {
      max-width: 86%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .MuiSelect-select {
    background: none !important;
  }
}
