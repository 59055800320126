@import "../../../theme/scss/variables.scss";

.sorting-info {
  .sorting-info-title {
    @media (max-width: 767px) {
      font-size: 14px !important;
      line-height: 18px !important;
    }
  }

  .MuiSelect-select:focus {
    background-color: $white !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $secondary !important;
  }

  .MuiFormLabel-root {
    color: $secondary !important;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 14px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
}
