@import "~bootstrap/scss/mixins";

.short-list-card {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 4px 0;

  .image-wrapper {
    flex-basis: 25%;
  }
}
