.basic-details-row {
  height: 75px;
  padding-bottom: 16px;
  border-top: 2px solid #efefef;

  &:nth-child(1) {
    padding-bottom: 12px;
    border: none;
  }

  .card-cell {
    .card-cell-title-small {
      font-size: 12px;
      line-height: 16px;
    }

    .reveal-text {
      font-size: 10px;
    }
  }
}
