.app-drop-down-item {
  .item-title {
    border: none;
    outline: none;
    color: #30336b;
    background: none;
    font-weight: 500;
    transition: color 0.2s;

    &::after {
      display: inline-block;
      margin-left: .255em;
      vertical-align: .255em;
      content: "";
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      color: #eb4d4b;
    }
  }

  .items-container {
    top: 36px;
    z-index: 1000;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);

    .item-sub-link {
      color: #212529;
      font-weight: 500;
      white-space: nowrap;
      text-decoration: none;
      background-color: transparent;

      &.item-button {
        border: none;
        outline: none;
        background: none;

        &:focus {
          outline: none;
        }
      }

      &:hover {
        background-color: #f8f9fa;
      }
    }
  }
}
