.edit-vehicle-form {
  .cancel-button {
    background-color: #999999 !important;
  }

  .actions-container {
    @media (max-width: 576px) {
      height: 92px;
    }
  }
}
