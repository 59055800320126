.auth-vehicle-info {
  .progress-container {
    width: 40px;
    min-height: 64px;
  }
  .not-right-vehicle {
    font-size: 12px;
  }

  @media (min-width: 769px) {
    .vehicle-info-title {
      margin-left: -22px;
      margin-right: -22px;
    }
  }
}
