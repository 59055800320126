.user-details-form {
  .input-group {
    @media (min-width: 768px) {
      width: 50%;
    }
  }

  .submit-button {
    @media (min-width: 768px) {
      width: 180px;
    }
  }
}
