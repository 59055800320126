.car-buy-report-card {
  min-width: 0;
  border-radius: 8px;
  height: fit-content;
  background-color: #efefef;

  .vehicle-no-report-item {
    font-size: 12px;
  }

  .report-card-title {
    color: #22a6b3;
    font-size: 16px;
  }

  .show-more-link {
    color: #30336b;
    text-decoration: underline;
  }

  .report-buy-button {
    height: 42px;
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
    background-color: #eb4d4b;
  }
}
